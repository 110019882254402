// import DataObject from 'o365.vue.ts'; //Yes it does

interface EditorInstance {
  editor: any; //FroalaEditor type of some sort
  db: any;
  contentColumn: string;
}

const editors: Record<string, EditorInstance> = {};

const setActiveEditor = (boxId: string, editor: EditorInstance) => {
  editors[boxId] = editor;
};

const getActiveEditor = (boxId: string): EditorInstance | undefined => {
  return editors[boxId];
};

 // Caret position in editor
let selectedNode:HTMLElement | null = null;
let selectedNodeOffset:number;

const setSelectedNodeAndOffset = (node:HTMLElement, nodeOffset:number) => {
    selectedNode = node;
    selectedNodeOffset = nodeOffset;
}
export { setActiveEditor, getActiveEditor, EditorInstance, selectedNode, selectedNodeOffset, setSelectedNodeAndOffset};

